import {FC} from 'react';
import {Grid} from '@mui/material';
import {
    AdvertisementCardWrapper
} from 'modules/theme/components/listing/cards/common/components/wrapper/AdvertisementCardWrapper';
import {
    Photo as PhotoPart
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/Photo';
import {
    ContentPart
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/ContentPart';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {createSeoString} from 'modules/seoString/createSeoString';

interface IAdvertisementCard {
    advertisement: IAdvertisement,
    detailUrl: string,
    onClick?: () => void;
    isFavoriteDisabled?: boolean;
    isComparisonDisabled?: boolean;
    onCompareClick?: () => void;
    onFavoriteClick?: () => void;
}

export const AdvertisementCard: FC<IAdvertisementCard> = ({
    advertisement,
    detailUrl,
    onClick,
    isFavoriteDisabled,
    isComparisonDisabled,
    onCompareClick,
    onFavoriteClick,
}) => {

    const {
        area,
        title,
        price,
        photos,
        seller,
        agency,
        address,
        packages,
        devProject,
        description,
        listingUrl,
        subcategory,
        attachments,
        isDevProject,
        isFavorite,
        isInComparison,
    } = advertisement;

    const {location} = address;
    const agencyName = agency?.agencyName;
    const {price: mainPrice, unitPrice} = price;
    const {isBasic, isTop, isPremium} = packages;
    const {previewPhotoUrl, thumbsPhotosUrl} = photos;
    const {has3d, hasVideo, hasFloorPlan} = attachments;
    const {isAgencyProfi, isSellerPro, sellerPhotoUrl} = seller;

    const alt = ['Fotka', 'inzeratu', createSeoString(title)].join('_');

    return <AdvertisementCardWrapper detailUrl={detailUrl} onClick={onClick}>
        <Grid
            gap={2}
            container
            display="flex"
            flexWrap={{xs: 'wrap', md: 'nowrap'}}
            flexDirection={{xs: 'column', md: 'row'}}
        >
            <Grid item xs={12} md={4} flexShrink={0}>
                <PhotoPart
                    alt={alt}
                    title={alt}
                    isTop={isTop}
                    has3d={has3d}
                    isBasic={isBasic}
                    hasVideo={hasVideo}
                    isPremium={isPremium}
                    isFavorite={isFavorite}
                    isDevProject={isDevProject}
                    hasFloowPlan={hasFloorPlan}
                    isAgencyProfi={isAgencyProfi}
                    isInComparison={isInComparison}
                    onCompareClick={onCompareClick}
                    previewPhotoUrl={previewPhotoUrl}
                    thumbsPhotosUrl={thumbsPhotosUrl}
                    onFavoriteClick={onFavoriteClick}
                    isFavoriteDisabled={isFavoriteDisabled}
                    isComparisonDisabled={isComparisonDisabled}
                />
            </Grid>

            <Grid item xs={12} md={8} flexShrink={0}>
                <ContentPart
                    area={area}
                    title={title}
                    price={mainPrice}
                    location={location}
                    isPremium={isPremium}
                    unitPrice={unitPrice}
                    agencyName={agencyName}
                    listingUrl={listingUrl}
                    devProject={devProject}
                    isSellerPro={isSellerPro}
                    description={description}
                    subcategory={subcategory}
                    isAgencyProfi={isAgencyProfi}
                    sellerPhotoUrl={sellerPhotoUrl}
                />
            </Grid>

        </Grid>
    </AdvertisementCardWrapper>;
};
