import {FC} from 'react';
import {Box} from '@mui/material';
import {PhotoItem} from 'modules/theme/components/listing/cards/common/components/photoItem/PhotoItem';
import {
    Badges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/Badges';

interface IPreviewPhoto {
    isTop?: boolean;
    has3d?: boolean;
    photoUrl?: string;
    isBasic?: boolean;
    hasVideo?: boolean;
    isPremium?: boolean;
    isDevProject?: boolean;
    hasFloowPlan?: boolean;
    isAgencyProfi?: boolean;
    alt?: string;
    title?: string;
    isFavorite?: boolean;
    isInComparison?: boolean;
    isFavoriteDisabled?: boolean;
    isComparisonDisabled?: boolean;
    onCompareClick?: () => void;
    onFavoriteClick?: () => void;
}

export const PreviewPhoto: FC<IPreviewPhoto> = ({
    has3d,
    isTop,
    isBasic,
    hasVideo,
    photoUrl,
    isPremium,
    isDevProject,
    hasFloowPlan,
    isAgencyProfi,
    alt,
    title,
    isFavorite,
    isInComparison,
    isFavoriteDisabled,
    isComparisonDisabled,
    onCompareClick,
    onFavoriteClick,
}) => {

    return <Box position="relative">
        <Badges
            isTop={isTop}
            has3d={has3d}
            isBasic={isBasic}
            hasVideo={hasVideo}
            isPremium={isPremium}
            hasFloowPlan={hasFloowPlan}
            isDevProject={isDevProject}
            isAgencyProfi={isAgencyProfi}
            isFavorite={isFavorite}
            isInComparison={isInComparison}
            isFavoriteDisabled={isFavoriteDisabled}
            isComparisonDisabled={isComparisonDisabled}
            onFavoriteClick={onFavoriteClick}
            onCompareClick={onCompareClick}
        />

        <PhotoItem
            alt={alt}
            width={200}
            height={203}
            title={title}
            photoUrl={photoUrl}
        />
    </Box>;
};
