import {FC, ReactNode} from 'react';
import Link from 'components/link/Link';
import {Theme, useMediaQuery} from '@mui/material';

interface AdvertisementCardWrapper {
    children: ReactNode;
    detailUrl: string;
    onClick?: () => void;
}

export const AdvertisementCardWrapper: FC<AdvertisementCardWrapper> = ({detailUrl, onClick, children}) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Link
        noUnderline
        onClick={onClick}
        zIndex={1}
        openInNewTab={!isMobile}
        href={detailUrl}
    >
        {children}
    </Link>;
};
