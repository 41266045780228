import {FC} from 'react';
import {PhotoItem} from 'modules/theme/components/listing/cards/common/components/photoItem/PhotoItem';

interface IThumbPhoto {
    photoUrl?: string;
}

export const ThumbPhoto: FC<IThumbPhoto> = ({photoUrl}) => {
    return <PhotoItem photoUrl={photoUrl} height={88} width={120}/>;
};
