import {FC} from 'react';
import {Box} from '@mui/material';
import {ThumbPhotos} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/thumbs/ThumbPhotos';
import {PreviewPhoto} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/PreviewPhoto';

interface IPhoto {
    isTop?: boolean;
    has3d?: boolean;
    isBasic?: boolean;
    hasVideo?: boolean;
    isPremium?: boolean;
    isDevProject?: boolean;
    hasFloowPlan?: boolean;
    isAgencyProfi?: boolean;
    previewPhotoUrl?: string;
    thumbsPhotosUrl: string[];
    alt?: string;
    title?: string;
    isFavorite?: boolean;
    isInComparison?: boolean;
    isFavoriteDisabled?: boolean;
    isComparisonDisabled?: boolean;
    onCompareClick?: () => void;
    onFavoriteClick?: () => void;
}

export const Photo: FC<IPhoto> = ({
    isTop,
    has3d,
    isBasic,
    hasVideo,
    isPremium,
    hasFloowPlan,
    isDevProject,
    isAgencyProfi,
    previewPhotoUrl,
    thumbsPhotosUrl,
    alt,
    title,
    isFavorite,
    isInComparison,
    isFavoriteDisabled,
    isComparisonDisabled,
    onCompareClick,
    onFavoriteClick,
}) => {
    const showThumbPhotos = thumbsPhotosUrl.length > 0 && isPremium;

    return <Box display="flex" flexDirection="column" gap={0.5}>
        <PreviewPhoto
            has3d={has3d}
            isTop={isTop}
            isBasic={isBasic}
            hasVideo={hasVideo}
            isPremium={isPremium}
            photoUrl={previewPhotoUrl}
            isDevProject={isDevProject}
            hasFloowPlan={hasFloowPlan}
            isAgencyProfi={isAgencyProfi}
            isFavorite={isFavorite}
            isInComparison={isInComparison}
            isFavoriteDisabled={isFavoriteDisabled}
            isComparisonDisabled={isComparisonDisabled}
            onFavoriteClick={onFavoriteClick}
            onCompareClick={onCompareClick}
            alt={alt}
            title={title}
        />
        {showThumbPhotos && <ThumbPhotos photosUrl={thumbsPhotosUrl}/>}
    </Box>;
};
