import {FC} from 'react';
import {Palette, Stack} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';


interface IPrice {
    price: string;
    unitPrice?: string;
}

export const Price: FC<IPrice> = ({price, unitPrice}) => {
    return <Stack
        gap={{xs: 1, md: 0}}
        direction={{xs: 'row', md: 'column'}}
        alignItems={{xs: 'baseline', md: 'end'}}
    >
        <Text variant="h4" mobileVariant="h5" semibold>
            {price}
        </Text>

        {unitPrice &&
            <Text variant="label1" color={(palette: Palette) => palette.labelSecondary}>
                {unitPrice}
            </Text>
        }
    </Stack>;
};
