import {FC} from 'react';
import {Stack} from '@mui/material';
import {ESubcategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import {
    Location
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/information/location/Location';
import {
    Parameters
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/information/parameters/Parameters';

interface IInformation {
    area?: number,
    location: string,
    subcategory: ESubcategory,
}

export const Information: FC<IInformation> = ({area, location, subcategory}) => {

    return <Stack direction="column" gap={.5}>
        <Location location={location}/>
        <Parameters area={area} subcategory={subcategory}/>
    </Stack>;
};
