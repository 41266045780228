import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { Text } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/mobile/partials/text/Text';
import { ESubcategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import { SellerPhoto } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/seller/SellerPhoto';
import { DevProjectLink } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/devProject/DevProjectLink';
import {
    IDevProject
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
interface IContent {
    price: string;
    area?: number;
    location: string;
    unitPrice?: string;
    isSellerPro?: boolean;
    sellerPhotoUrl?: string;
    devProject?: IDevProject;
    subcategory: ESubcategory;
}

export const Content: FC<IContent> = ({
    area,
    price,
    location,
    unitPrice,
    devProject,
    subcategory,
    isSellerPro,
    sellerPhotoUrl,
}) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <Stack direction={'column'} gap={0.5}>
            <Stack
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent="space-between"
            >
                <Text
                    area={area}
                    price={price}
                    location={location}
                    unitPrice={unitPrice}
                    subcategory={subcategory}
                />

                <SellerPhoto photoUrl={sellerPhotoUrl} isPro={isSellerPro} />
            </Stack>
            {(devProject && isClient) && (
                <DevProjectLink devProject={devProject}/>
            )}
        </Stack>
    );
};
